<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useHead } from '@vueuse/head'
import { useQuoteStore } from '@/stores/quote'
import QuoteFormTitle from '@/components/Quote/QuoteFormTitle.vue'
import { delay } from '@/utils/helpers'
import { redirectUrlClicked } from '@/utils/api'

useHead({
  title: 'Sorry - Zixty Quote'
})

const quote = useQuoteStore()

const onSubmit = (e: Event) => {
  //
}

const redirectValue = computed(() => quote?.responses?.server_error?.redirect)

const redirectUrl = computed(() => quote?.responses?.server_error?.redirect?.redirect_url)

const onPartnerRedirectUrlClick = async () => {
  if (quote?.responses?.server_error?.correlation_id) {
    await redirectUrlClicked({
      correlation_id: quote.responses.server_error.correlation_id,
      clicked: true
    })
  }
}

onMounted(async () => {
  if (quote?.responses?.correlation_id) {
    quote.helpers.recordWebEvent('SORRY')
  }

  // clear these as they are no longer needed
  await delay(100)
  quote.responses.correlation_id = ''
  quote.responses.quote_expires_at = ''
})
</script>

<template>
  <div class="w-full">
    <div class="pt-6 mx-auto">
      <div class="py-3 md:py-6 flex justify-center text-center" v-if="!redirectUrl">
        <QuoteFormTitle> Sorry, not this time </QuoteFormTitle>
      </div>
      <div class="mx-auto">
        <form id="form-quote-sorry" class="mx-auto" @submit.prevent="onSubmit" novalidate>
          <div
            class="py-3 w-full flex flex-col justify-center text-center text-[#595959] max-w-lg mx-auto"
          >
            <div v-if="redirectUrl">
              <div class="py-3" v-if="redirectValue?.redirect_main_text">
                <span v-html="redirectValue.redirect_main_text"></span>
              </div>
              <div class="py-3">
                <a
                  :href="redirectUrl"
                  target="_blank"
                  rel="noopener"
                  @click="onPartnerRedirectUrlClick"
                >
                  <button
                    type="button"
                    id="form-quote-sorry--restart-quote-button"
                    class="p-3 px-8 rounded-full hover:opacity-90 cursor-pointer"
                    :class="[`font-medium bg-red-500 text-white`]"
                  >
                    {{ redirectValue?.redirect_button_text || 'Visit Partner' }}
                  </button>
                </a>
              </div>
            </div>
            <div v-else>
              <p class="my-1.5">Sorry - it looks like we can't offer you cover right now.</p>
              <p class="my-1.5">
                We're continuously working to offer eco friendly short term car and van insurance to
                more people, so please check back in the future.
              </p>
              <p class="my-1.5">Thanks for thinking of Zixty.</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
