<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import type { ApiErrorResponse } from '@/utils/api-types'
import { useRouter } from 'vue-router'
import RestartQuoteButton from '@/components/Quote/RestartQuoteButton.vue'
import { useQuoteStore } from '@/stores/quote'

// props
export interface Props {
  error?: ApiErrorResponse
}

const quote = useQuoteStore()

const props = withDefaults(defineProps<Props>(), {
  //
})

// router
const $router = useRouter()

// helpers
const in_array_str = (needle: string, haystack: Array<string>) => haystack.indexOf(needle) > -1

const extract_what_to_say_error_number = (msg: string): number => {
  const norm = typeof msg === 'string' ? msg : `${msg}`
  const mtch = norm.match(/\(Error (\d+)\)/)
  const num = !!mtch && !!mtch[1] ? Number(mtch[1]) : 0
  return num || 0
}

const scroll_to = (top: number) => {
  window.scrollTo({
    top: top,
    behavior: 'smooth'
  })
}

// refs
const $el = ref<HTMLDivElement>()
const code = ref<string>(props.error?.code || '')
const what_to_do = ref<string>(props.error?.what_to_do || '')
const what_to_say = ref<string>(
  props.error?.what_to_say || 'An unexpected error has occurred. Please try again.'
)
const what_to_say_error_number = ref<number>(
  extract_what_to_say_error_number(what_to_say.value || '')
)

// journey checks
const journey_sorry = computed(() =>
  in_array_str(what_to_do.value, ['BLOCK', 'BLOCK_TOO_MANY_QUOTES'])
)

const journey_restart = computed(() =>
  in_array_str(what_to_do.value, [
    'PAYLOAD_ERROR',
    'JOURNEY_FLOW_ERROR',
    'RESTART_QUOTE',
    'SELECT_OR_ADD_VEHICLE'
  ])
)

const journey_try_again = computed(() => in_array_str(what_to_do.value, ['TRY_AGAIN']))

const journey_no_action = computed(() => in_array_str(what_to_do.value, ['NO_ACTION']))

// on mounted
onMounted(() => {
  // update the server error
  if (props?.error) {
    quote.responses.server_error = props.error
  }

  if (!!props.error && !!$el.value) {
    scroll_to($el.value?.offsetTop || 0)
  }

  // redirect to sorry page
  if (!!journey_sorry.value || props?.error?.redirect) {
    $router.push({
      name: 'quote.sorry'
    })
  }

  // hide all the other elements in the form when this happens
  if (!!journey_restart.value || !!journey_no_action.value) {
    $el.value?.parentNode?.childNodes.forEach((childNode) => {
      const $childNode = childNode as HTMLElement
      if ($childNode && $childNode !== $el.value && $childNode?.style?.display) {
        $childNode.style.display = 'none'
      }
    })
    scroll_to(0)
  }
})
</script>

<template>
  <div v-if="!!error" ref="$el" class="server-error">
    <div class="server-error-hidden">
      <div v-if="!!code" id="zixty-error-code">{{ code }}</div>
      <div v-if="!!what_to_say_error_number" id="what-to-say-error-number">
        {{ what_to_say_error_number }}
      </div>
    </div>
    <div class="server-error-visible">
      <div v-if="!!what_to_say" id="what-to-say" :data-what-to-do="error.what_to_do">
        <p>{{ what_to_say }}</p>
      </div>
      <div v-if="journey_restart" class="server-error-restart">
        <RestartQuoteButton color="bg-red-500"> Restart Quote </RestartQuoteButton>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.server-error {
  @apply my-6 py-3 border-2 border-red-100 bg-red-100 rounded-md;
  .server-error-hidden {
    div {
      @apply float-left p-0 m-0 text-[1px] leading-none text-red-100;
    }
  }
  .server-error-visible {
    @apply text-red-500 text-sm my-3 px-6;
    .server-error-restart {
      @apply my-6 text-center;
    }
  }
}
</style>
